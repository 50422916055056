const donation_data = [
  {
    id: 1,
    image: "rumah-kinasih.jpg",
    title: "SENYUM UNTUK RUMAH KINASIH",
    body: "<p><small>  Kamu masih menunda untuk berbuat kebaikan? Jangan ya!  Karena kami hadir untuk kamu semua yang ingin berbagi  kepada sesama.</small></p><p><small>  Edisi kali ini kita akan mengajak untuk berinteraksi  dengan Rumah Kinasih yang menampung teman-teman  disabilitas dan gangguan mental. Kegiatannya nanti akan  dilakukan secara paralel meliputi pengenalan obat herbal,  komitmen hidup sehat dan sesi obrol erat. Seru banget kan!</small></p><p><small>  Bagi kamu yang tertarik, bisa banget gabung kegiatan kami  atau melalui donasi di postingan kami. Ditunggu aksi  nyatamu sekarang!!</small>",
    periode: "22 September 2024 - 6 Oktober 2024",
    status: "Tutup dan Terpenuhi",
  },
  {
    id: 2,
    image: "dinasi-rumah-kinasih-2.PNG",
    title: "HADIR KEMBALI!!! ",
    body: "<p><small> Halo Smart People, gimana tahun 2024 sudah berbagi kebaikan apa saja nih? Sudah ada rencana baik di 2025?</small></p><p><small>Nah daripada bingung, bisa banget Smart People membagikan kebaikannya melalui donasi untuk Senyum Rumah Kinasih #2. Kali ini kegiatan-nya akan lebih banyak dan tentunya bermanfaat bagi teman-teman ODGJ yang bergabung di Rumah Kinasih. Selain itu, Smart People juga bisa bergabung langsung di tanggal 4 Januari nanti, catat tanggalnya!!!</small></p> <p><small>Yuk, lakukan aksi kecil dan berdampak bagi sesama! Cek postingan kami!!</small></p>",
    periode: "29 November 2024 - 2 Januari 2025",
    status: "Terbuka Untuk Donasi",
  },
];

export default donation_data;
